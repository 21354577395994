* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

 /* dropdown css  start*/
 .react-select__control {
  border: 1px solid #d6d6d6 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  min-height: 50px !important;
}

.react-select__control:hover {
  border: 1px solid #d6d6d6 !important;
}

.react-select__clear-indicator {
  padding: 0px !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__input {
  padding-left: 15px;
}

.react-select__placeholder {
  padding-left: 15px;
  color: darkgray !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
  display: inline-block;
  overflow: hidden;
}

.react-select__menu {
  padding: 0px 5px;
  box-shadow: none !important;
  border: 1px solid #d6d6d6;
  border-radius: 10px !important;
  margin: 5px 0px !important;
}

.react-select__option {
  min-height: 50px !important;
  border-radius: 10px;
  margin-top: 5px;
  padding-left: 15px;
  display: flex !important;
  align-items: center;
}

.react-select__menu-notice {
  min-height: 40px !important;
  display: flex !important;
  align-items: center;
}

.react-select__option .selected {
  padding-left: 15px;
  background-color: #eff6ff !important;
}

.react-select__single-value {
  width: 100%;
  padding-left: 15px;
  color: #1b3c69 !important;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 10px;
}

.react-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: rgb(182, 186, 189);
  border-radius: 10px;
}

.view_basic_info .react-select__placeholder {
  color: #b9b2b2 !important;
}


.checkbox-dropdown .react-select__placeholder {
  padding-left: 7px;
}
.checkbox-dropdown .react-select__input {
  padding-left: 7px;
}

.checkbox-dropdown .unchecked {
  width: 26px;
  height: 26px;
  border: 1px solid #484848;
  border-radius: 6px;
}

.checkbox-dropdown .checked {
  width: 26px;
  height: 26px;
  background-color: #f26a42;
  border: 1px solid #f26a42;
  border-radius: 6px;
}

.checkbox-dropdown .checked svg {
  color: #fff;
}

.checkbox-dropdown .react-select__multi-value__remove:hover {
  background-color: #f26a42;
  color: #fff;
}

.checkbox-dropdown .react-select__value-container {
  margin-left: 9px;
}

.checkbox-dropdown .react-select__menu-list {
  max-height: 170px;
}

.checkbox-dropdown .react-select__menu {
  color: black;
}

.checkbox-dropdown .react-select__option {
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  color: black;
  margin-top: 0px;
  padding: 0px 12px;
  padding-left: 0px;
}

.checkbox-dropdown .react-select__option--is-selected {
  font-family: ProximaNova-Semibold;
}

.checkbox-dropdown .react-select__option:hover {
  background-color: #fff;
  font-family: ProximaNova-Semibold;
  color: black;
}

.checkbox-dropdown .react-select__option:hover .MuiButtonBase-root:hover {
  background-color: transparent;
}

.checkbox-dropdown .react-select__option:active { 
  background-color: #fff;
  color: black;
  font-family: ProximaNova-Semibold;
}

.checkbox-dropdown .react-select__multi-value {
  border-radius: 6px;
  color: #fff;
  background-color: #f26a42;
  padding: 4px;
}

.checkbox-dropdown .react-select__multi-value__label {
  color: #fff;
}

.competency-dropdown .react-select__control {
  min-height: 40px !important;
  border: 1px solid #787878 !important;
}


.competency-dropdown .react-select__control:hover {
  border: 1px solid #787878 !important;
}

.competency-dropdown .react-select__option {
  min-height: 40px !important;
  margin-top: 2px !important;
}

.competency-dropdown .react-select__single-value {
  color: black !important;
}

.competency-dropdown .react-select__menu-notice {
  min-height: 30px !important;
}
/* dropdown css end */

.text-danger {
  margin-top: 2px;
  font-size: 14px;
  color: #f94b4b;
}

.custom-table tbody tr:last-child td {
  border: none;
}

.table-scrollbar::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: 10px;
}

.table-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.table-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(182, 186, 189);
  border-radius: 10px;
}
/* custom table end */

/* custom phone input start*/
.country-phone-input input{
  width: 100% !important;
  height: 45px !important;
  border: 1px solid #787878 !important;
  border-radius: 10px !important;
}

.country-phone-input .flag-dropdown {
  border: 1px solid #787878 !important;
  border-radius: 10px 0px 0px 10px !important;
}

.country-phone-input .flag-dropdown.open .selected-flag,
.country-phone-input .flag-dropdown.open .selected-flag:hover,
.country-phone-input .selected-flag {
  border-radius: 10px 0px 0px 10px !important;
}

.country-phone-input .selected-flag,
.country-phone-input .selected-flag:hover,
.country-phone-input .selected-flag:focus {
  background-color: #f5f5f5 !important;
}

.country-phone-input .country-list {
  width: 245px !important;
  max-height: 230px !important;
}

.country-phone-input .search-box {
  height: 35px !important;
  margin-left: 1px !important;
}

.country-phone-input .search-box::placeholder{
  font-size: 15px !important;
}

.country-phone-input .country-list .country {
  padding-left: 15px;
}

.country-phone-input .country-list::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: 10px;
}

.country-phone-input .country-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.country-phone-input .country-list::-webkit-scrollbar-thumb {
  background: rgb(182, 186, 189);
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .country-phone-input .country-list{
    width: 205px !important;
  }
}

.view_basic_info .country-phone-input input {
  height: 49px !important;
  border: 1px solid #d6d6d6 !important;
}

.view_basic_info .react-tel-input .country-list .search-box {
  height: 35px !important;
}

.view_basic_info .country-phone-input .country-list {
  width: 231px !important;
}
/* custom phone input end */

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 80vw !important;
    left: inherit !important;
  }
}

 /* Custom pagination */
.custom-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  color: #f94b4b !important;
}

.custom-pagination td {
  border-bottom: none;
}

.custom-pagination ul .Mui-selected {
  background-color: #f94b4b !important;
  color: #fff !important;
  font-weight: bold ;
}

.custom-pagination .MuiPaginationItem-page {
  color: #bebebe;
  border-radius: 6px;
  border: solid 1px #bebebe !important;
  background-color: #fafafa !important;
}

.custom-pagination nav ul li:last-child > button {
  border: 1px solid #f94b4b !important ;
  color: #f94b4b;
  border-radius: 6px;
}

.custom-pagination nav ul li:first-child > button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.custom-pagination nav ul li:nth-child(2) > button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.custom-pagination nav ul li:nth-last-child(2) > button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

#drag-questions-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* || Login Participant  */

.participant_login {
  display: block;
  font-family: 'Proxima Nova Rg';
  background-color: #1b3c69 ;
  padding: 20px;
}


#root .css-view-1dbjc4n {
  width: 100% !important;
}
.participant_login .form_container {
  display: block;
  margin: 0 auto;
  max-height: 527px;
  height: 100%;
  background-color: #fff;
  min-width: 500px !important;
  border-radius: 20px;
  padding: 50px;
}
.participant_login .form_container .img_logo {
  height: 58px;
  object-fit: cover;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.participant_login .field-label {
  height: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  color: #333;
  display: inline-block;
  font-family: "ProximaNova-Regular";
}

.participant_login .validation_errors {
  color: #cd1414;
  height: 20px;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.participant_login .form-control {
  margin-bottom: 22px;
}

.participant_login .form-controlPwd {
  margin-bottom: 16px;
}
.participant_login .custom-form-control {
  max-width: 398px;
  max-height: 52px;
  width: 100%;
  height: 100%;
  padding: 15px 11px 13px 18px;
  border-radius: 6px;
  background-color: #fff;
  font-family: 'Proxima Nova Alt Rg';
  font-size: 18px;
  font-weight: 400;
  line-height: 52px;

}

.participant_login .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 30px;
}
.participant_login .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 102px;
  gap: 5px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px 15px;
}
.participant_login .right-side img {
  margin-right: 5px;
}
.participant_login .right-side p {
  color: #fff;
}
.participant_login .right-side .toggle-icon {
  margin-left: 10px;
}

.participant_login .text_footer {
  width: 100%;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #8295af;
  font-family: "ProximaNova-Semibold";
  margin-top: 20px;
}

.participant_login .btn_login {
  max-width: 400px;
  width: 100%;
  height: 60px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 10px 0 20px;
  padding: 18px 16px 17px;
  background-color: #f26a42;
}

.participant_login .forgot_password {
  display: block;
  margin: 0 auto;
  font-size: 16px;
  margin-top: 38px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1b3c69;
  text-decoration: underline;
  font-family: "ProximaNova-Light" !important;
}

.participant_login .login_btns {
  max-width: 400px;
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #f26a42;
  box-shadow: none;
  cursor: pointer;
  border: none !important;
} */

.participant_login  .login_btns  .btn_text {
  font-family: "ProximaNova-Semibold";
  font-size: 22px;
  font-weight: 600;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.participant_login .shrm_logo .brand_img{
  width: auto;
  height: 95px;
 }
 .participant_login .custo{
  border: 2px solid red;
  max-width: 398px;
  max-height: 52px;
  width: 100%;
  height: 100%;
  padding: 15px 11px 13px 18px;
  border-radius: 6px;
  background-color: #fff;
  font-family: 'Proxima Nova Alt Rg'; 
  font-size: 18px;
 } 
/* -------------------------------- Responsive Media ---------------------------- */

@media screen and (max-width: 767px) {
  .participant_login .container{
  width: 100%;
 }
 .participant_login .form_container_sm{
  width: 80%;
  padding: 20px;
  height: auto;
 }
 .participant_login .shrm_logo .brand_img{
  width: auto;
  height: 55px;
 }
}

@media (min-width: 768px) and (max-width: 990px) {
  .participant_login .container{
    width: 100%;
   }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .participant_login .container{
    width: 100%;
   }
}

@media screen and (min-width: 1200px) {
  .participant_login .container{
    width: 100%;
   }
}



/* || Welcome Page || */

.welcomePage {
  padding: 2px 0;
}

.welcomePage .welcomeTitle {
  font-weight: 600;
  padding-bottom: 30px;
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  color: #333;
}

.welcomePage .sectionTitle {
  font-weight: 600;
  padding-bottom: 15px;
  font-family: "ProximaNova-Semibold";
  font-size: 22px;
  color: #000;
}

.welcomePage .sectionBody {
  font-family: "ProximaNova-Light";
  font-size: 18px;
  font-weight: 300;
  color: #000;
  line-height: 1.44;
  text-align: left;
}

.welcomePage .welcomeImg {
  height: 300px;
  margin: -30px 75px 0 0;
  object-fit: contain;
}

.welcomePage .welcomeImgTwo {
  width: 87%;
  margin: 0px 0 0px 50px;
  object-fit: contain;
}

.welcomePage .sectionWrapper ul li::marker {
  color: #8BADDB;
  font-size: 28px;
}

.welcomePage .sectionWrapper ul li {
  margin-left: 20px;
  margin-top: 10px;
}

.welcomePage .sectionWrapper {
  margin: 0 !important;
  width: 100%;
  padding: 70px 0px 0;
}

.welcomePage .sectionWrapper .MuiGrid-item {
  padding: 0px !important;
}

.welcomePage .videoSectionWrapper {
  /* margin-top: 60px !important; */
  padding-top: 25px;
}

.welcomePage .imageWrapper {
  text-align: center;
  display: block;
}

.welcomePage .imageWrapperResponsive {
  display: none;
}

.welcomePage .videoWrapper {
  text-align: center;
  margin: 54px 0 54px;
  position: relative;
}

.welcomePage .video {
  border: 1px solid black;
  width: 835px;
  height: 470px;
  object-fit: contain;
}

.welcomePage .pauseBtn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.welcomePage .playBtn {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 450px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.welcomePage .dashboardBtn {
  color: #fff;
  cursor: pointer;
  background-color: #F26A42;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 18px 20px;
  margin: 50px auto;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.09;
  font-family: "ProximaNova-Semibold";
  align-items: center;
}


@media only screen and (max-width: 960px) {
  .welcomePage .video {
      width: 100% !important;
      height: auto !important;
  }

  .welcomePage .welcomeImgTwo {
      width: 100% !important;
      max-width: 360px;
      height: unset !important;
      margin: 35px auto 0 !important;
  }

  .welcomePage .welcomeImg {
      width: 100% !important;
      max-width: 360px;
      height: unset !important;
      margin: 20px 0 !important;
  }

  .welcomePage .playBtn {
      width: 100% !important;
  }

  .welcomePage .pauseBtn {
      width: 40px !important;
      height: 40px !important;
  }

  .welcomePage .welcomeTitle {
      font-size: 20px;
      color: #000;
      padding-bottom: 20px;
  }

  .welcomePage .sectionBody {
      font-size: 16px;
      line-height: 1.38;
  }

  .welcomePage .imageWrapper {
      display: none;
  }

  .welcomePage .imageWrapperResponsive {
      display: block;
      text-align: center;
  }

  .welcomePage .sectionWrapper {
      padding-top: 40px;
  }

  .welcomePage .videoWrapper {
      margin: 20px 0;
  }

  .welcomePage .dashboardBtn {
      font-size: 18px;
      padding: 12px 16px;
      margin: 30px auto;
      width: 220px;
  }
}

/* || Check Email Page || */

.checkEmail {
  background: #1B3C69;
  height: 100vh;
  width: 100%;
  position: relative;
}

.checkEmail .shrmlogo {
  position: absolute;
  top: 30px;
  right: 0px;
  width: 125px;
  height: 70px;
  object-fit: contain;
}

.checkEmail .contentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkEmail .whiteBox {
  width: 500px;
  padding: 50px 80px;
  border-radius: 20px;
  background-color: #fff;
}

.checkEmail .title {
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #1b3c69;

}

.checkEmail .content {
  font-family: "ProximaNova-Regular";
  margin: 31px 0 0;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  color: #000;

}

.checkEmail .copyright {
  font-family: "ProximaNova-Light";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.29;
  color: #8295af;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 960px) {
  .checkEmail .whiteBox {
      padding: 30px 20px;
      max-width: 335px;
  }

  .checkEmail .title {
      font-size: 24px;
  }

  .checkEmail .content {
      font-size: 16px;
      line-height: 1.25;
  }

  .checkEmail .copyright {
      font-size: 14px;
      line-height: 1.29;
  }

  .checkEmail .shrmlogo {
      top: 20px;
      left: 0;
      right: unset;
      width: 87px;
      height: 50px;
  }
}

@media only screen and (max-width: 360px) {
  .checkEmail .whiteBox {
      padding: 30px 20px;
      max-width: 280px;
  }
}


/* || Basic Information  Page || */


a{
  text-decoration: none;
}
.view_basic_info {
  margin: 60px 105px;
  padding: 40px 40px 35px 40px;
  border-radius: 20px;
  background-color: #fff;
}

.text_footer_basic_info{
width: 100%;
text-align: center;
font-size: 14px;
color: #8295af;
}


.page_title {
  font-weight: 600;
  height: 50px;
  align-items: center;
  font-size: 32px;
  font-family: "Proxima Nova Rg";
  padding-left: 12px;
}
.image_logo {
  padding-top: 30px;
  padding-right: 30px;
}
.select_feild_container {
  width: 244px;
  width: 100%;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}
.textlabel_text {
  width: 95px;
  height: 24px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333;
  font-family: "ProximaNova-Regular";
}


.basic_info_submit_btn {
  width: 127px;
  height: 60px;
  background: #f26a42 !important;
  color: white !important;
  text-transform: none !important;
  margin: 15px 0px 12px 7px !important;
  padding: 18px 16px;
}
.input-feild-containers {
  min-width: 100%;
  width: 100%;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-feild-containers::-webkit-input-placeholder {
   font-family: "Proxima Nova Th"; 
}
.option-placeholder {
  color: #999;
}
.select_input_feild {
  width: 100%;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.select_input_feild:hover {
  border-bottom: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.select_input_feild::before {
  border-bottom: 0;
}
.select-field {
  width: 100% !important;
}
.select-field-rectange-2 {
  width: 100% !important;
}
.input_grid_3 {
  width: 244px;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.MuiFormControl-root .MuiInputLabel-root {
   font-family: "Proxima Nova Th";
}
.login-form {
  justify-content: center;
  min-height: 90vh;
}
.button-block {
  width: 100%;
}
.login-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}

.select_input_component {
  min-width: "100%";
}
.logo_image {
  max-width: 166px;
  height: 95px;
}
.MuiOutlinedInput-root {
  height: 52px !important;
}
.basic-info-textariafeild {
  width: 100%;
  height: 115px;
  padding: 14px 5px 77px 17px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.submit_btn_container {
  display: flex;
  justify-content: right;
}
.MuiFormHelperText-contained {
  margin: 0;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .view_basic_info {
    margin: 10px 0px 10px 0px;
    margin-top: 75px;
    padding: 2px;
  }

  .basic_info_container {
    width: 100%;
    padding: 15px;
  }

  .input-feild-containers {
    display: grid;
    width: 100%;
  }
  .select-field {
    width: 100%;
  }
  .select-field-rectange-2 {
    width: 100%;
  }
  .select-input-container {
    min-width: 80%;
  }
  .select_input_component {
    min-width: auto;
  }
  .page_title {
    font-size: 25px;
  }
 
  .logo_image {
    width: 100px;
    height: 60px;
  }
  .basic_info_submit_btn {
    width: 100%;
    height: 60px;
    background: rgb(242, 106, 66);
    color: white;
    text-transform: none;
    margin: 15px 21px 10px 30px;
    padding: 18px 16px;
    font-size: 21px !important;
  }
}
@media only screen and (min-width: 992px) {
  .view_basic_info {
    margin: 60px 70px;
    padding: 35px 35px 30px 35px;
    border-radius: 20px;
    background-color: #fff;
  }
  .logo_image {
    width: 106px;
    height: 65px;
  }
}
@media only screen and (min-width: 1280px) {
  .view_basic_info {
    margin: 50px 222px 55px;
    border-radius: 20px;
    background-color: #fff;
  }

  .logo_image {
    width: 166px;
    height: 95px;
  }
  .image_logo {
    padding-right: 0;
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .image_logo {
    padding-top: 30px;
    padding-right: 30px;
  }
  .view_basic_info {
    margin: 60px 15px 10px 15px;
  }
  .basic-info-textariafeild {
    width: 100%;
  }
  .basic_info_submit_btn {
    text-transform: none !important;
    background-color: #f26a42 !important;
    width: 100%;
    margin: 12px 0px 10px 0px !important;
    color: #fff !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .view_basic_info {
    padding: 18px !important;
  }
  .logo_image {
    width: 105px !important;
    height: 60px !important;
  }

}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  /* .forget_password_container {
    margin: 150px 208px 136px 208px !important;
} */
}

.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 15px !important;
}
.button_grid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 12px !important;
}


.basic_form{
  margin: 0 !important;
}




/* || Forget Password || */

.forgetPassword{
 background-color: #1b3c69;
 padding: 35px;
 flex: 1;
}



.forgetPassword h2{
  height: 10px;
  margin: 0 42px 59px 41px;
  font-family: ProximaNova-Bold;
  font-size: 29px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1b3c69;
}

.forgetPassword .box_container{
  display: block;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  max-height: 365px;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px;
  
}
.forgetPassword .forget-text{
  font-size: 18px;
  height: 36px;
  text-align: center;
  margin-bottom: 25px;
}



.forgetPassword .forgetPassword_btns{
  display: flex;
  gap: 23px;
  margin: 0;
  margin-top: 25px;
  position: fixed;
}

.forgetPassword .cancel_btn {
  border: none;
  font-family: ProximaNova-Semibold;
  font-size: 22px;
  font-weight: 600;
  border-radius: 10px;
  border: solid 1px #1b3c69;
  color:#1b3c69;
  text-align: center;
  background-color: #fff;
  padding: 14px 59px 14px 58px;
}

.forgetPassword .submit_btn {
  border: none;
  font-family: ProximaNova-Semibold;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  border: solid 1px #1b3c69;
  background-color: #1b3c69;
  padding: 14px 50px;
}



 .forgetPassword .text_footer{
  width: 100%;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  line-height: 1.29;
  letter-spacing: normal;
  color: #8295af;
  margin-top: 20px;
 }

/* -------------Responsiveness ----------------- */

@media screen and (max-width : 480px) {
  .forgetPassword .box_container {
    padding: 50px 20px 50px 40px;
    width: calc(100% - 40px);
    margin-top: 20px;
  }

  .forgetPassword .cancel_btn, 
  .forgetPassword .submit_btn {
    padding: 14px 20px;
    margin-top: 25px;
  }

  .forgetPassword h2 {
    margin: 0 0 59px 0;
    font-size: 26px;
  }
  .forgetPassword p {
    font-size: 16px;
  }
  
   .forgetPassword-btns{
    margin-top: 10px;
  } 
}
 

