.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.title {
  margin-left: 1em;
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

/* the calendar root */
.fc {
  width: 100%;
  margin: 0 auto;
}

/* For Toolbar */
.fc-toolbar-chunk {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* For Next and Previous Button */
.fc-next-button,
.fc-prev-button {
  background: none !important;
  color: rgb(0, 0, 0) !important;
  border: none !important;
  outline: none !important;
}

/* For Title */
.fc-toolbar-title {
  height: 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  color: #000;
}

/* Today Button */
.fc-today-button {
  background-color: #eff6ff !important;
  color: #1b3c69 !important;
  font-weight: bold !important;
  border: none !important;
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
}

/* display current day*/
.fc-day-today {
  background-color: #dff8d2 !important;
  color: #288a0a !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .paper {
  background-color: white;
  padding: 2rem;
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 10px;
  outline: none !important;
}
.btnContainer{
  margin-top: 1rem;
}
.btnCancel {
  color: #1b3c69 !important;
  border-color: #1b3c69 !important;
  text-transform: capitalize !important;
}

.btnSubmit {
  margin-left: 2rem !important;
  background-color: #1b3c69 !important;
  color: white !important;
  text-transform: capitalize !important;
}
.closeIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.closeIconLeft {
  position: absolute;
  top: 0.5rem;
  right: unset;
  left: 0.5rem;
}
.modal-title {
  margin: 10px 334px 20px 0;
  font-size: 30px;
  font-weight: bold;
  color: #1b3c69;
}

.dropdownTitle {
  font-size: 18px;
  font-weight: normal;
  color: #000;
}

.boldText {
  font-weight: 600;
}

.dropdown {
  width: 500px;
  height: 42px;
  margin: 15px 10px 0px 0;
  padding: 10px 18px 10px 18px;
  border-radius: 10px;
  border: solid 1px #787878 !important;
  background-color: #fff;
}

.modal2 {
  display: flex;
  justify-content: center;
}

.modalTitle2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #288a0a;
  margin: 15px 147px 10px 133px;
}

.modalText2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: #000;
}

.modalImg {
  width: 46px;
  height: 46px;
  margin: 10px 239px 15px 221px;
}
