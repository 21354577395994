.fc-daygrid-event-harness {
  margin-bottom: 20px;
}

td.fc-daygrid-day {
  cursor: pointer;
}

.swal2-popup {
  font-family: ProximaNova-Bold, Serif, Sans-serif !important ;
}

.CheckBox {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.red > input[type="checkbox"] {
  accent-color: #f94b4c;
}

.green > input[type="checkbox"] {
  accent-color: #dff8d2;
}
