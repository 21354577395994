.welcomePage {
    padding: 2px 0;
}

.welcomePage .welcomeTitle {
    font-weight: 600;
    padding-bottom: 30px;
    font-family: ProximaNova-Bold, Serif, Sans-serif !important;
    font-size: 32px;
    color: #333;
}

.welcomePage .sectionTitle {
font-weight: 600;
    padding-bottom: 15px;
    font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
    font-size: 22px;
    color: #000;
}

.welcomePage .sectionBody {
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-size: 18px;
    font-weight: 300;
    color: #000;
    line-height: 1.44;
    text-align: left;
}

.welcomePage .welcomeImg {
    height: 300px;
    margin: -30px 75px 0 0;
    object-fit: contain;
}

.welcomePage .welcomeImgTwo {
    width: 87%;
    margin: 0px 0 0px 50px;
    object-fit: contain;
}

.welcomePage .sectionWrapper ul li::marker {
    color: #8BADDB;
    font-size: 28px;
}

.welcomePage .sectionWrapper ul li {
    margin-left: 20px;
    margin-top: 10px;
}

.welcomePage .sectionWrapper {
    margin: 0 !important;
    width: 100%;
    padding: 70px 0px 0;
}

.welcomePage .sectionWrapper .MuiGrid-item {
    padding: 0px !important;
}

.welcomePage .videoSectionWrapper {
    /* margin-top: 60px !important; */
    padding-top: 25px;
}

.welcomePage .imageWrapper {
    text-align: center;
    display: block;
}

.welcomePage .imageWrapperResponsive {
    display: none;
}

.welcomePage .videoWrapper {
    text-align: center;
    margin: 54px 0 54px;
    position: relative;
}

.welcomePage .video {
    border: 1px solid black;
    width: 835px;
    height: 470px;
    object-fit: contain;
}

.welcomePage .pauseBtn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.welcomePage .playBtn {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 450px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.welcomePage .dashboardBtn {
    color: #fff;
    cursor: pointer;
    background-color: #F26A42;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 18px 20px;
    margin: 50px auto;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.09;
    font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
    align-items: center;
}


@media only screen and (max-width: 960px) {
    .welcomePage .video {
        width: 100% !important;
        height: auto !important;
    }

    .welcomePage .welcomeImgTwo {
        width: 100% !important;
        max-width: 360px;
        height: unset !important;
        margin: 35px auto 0 !important;
    }

    .welcomePage .welcomeImg {
        width: 100% !important;
        max-width: 360px;
        height: unset !important;
        margin: 20px 0 !important;
    }

    .welcomePage .playBtn {
        width: 100% !important;
    }

    .welcomePage .pauseBtn {
        width: 40px !important;
        height: 40px !important;
    }

    .welcomePage .welcomeTitle {
        font-size: 20px;
        color: #000;
        padding-bottom: 20px;
    }

    .welcomePage .sectionBody {
        font-size: 16px;
        line-height: 1.38;
    }

    .welcomePage .imageWrapper {
        display: none;
    }

    .welcomePage .imageWrapperResponsive {
        display: block;
        text-align: center;
    }

    .welcomePage .sectionWrapper {
        padding-top: 40px;
    }

    .welcomePage .videoWrapper {
        margin: 20px 0;
    }

    .welcomePage .dashboardBtn {
        font-size: 18px;
        padding: 12px 16px;
        margin: 30px auto;
        width: 220px;
    }
}

.checkEmail {
    background: #1B3C69;
    height: 100vh;
    width: 100%;
    position: relative;
}

.checkEmail .shrmlogo {
    position: absolute;
    top: 30px;
    right: 0px;
    width: 125px;
    height: 70px;
    object-fit: contain;
}

.checkEmail .contentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkEmail .whiteBox {
    width: 500px;
    padding: 50px 80px;
    border-radius: 20px;
    background-color: #fff;
}

.checkEmail .title {
    font-family: ProximaNova-Bold, Serif, Sans-serif !important;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #1b3c69;

}

.checkEmail .content {
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    margin: 31px 0 0;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    color: #000;

}

.checkEmail .copyright {
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #8295af;
    text-align: center;
    margin-top: 20px;
}

@media only screen and (max-width: 960px) {
    .checkEmail .whiteBox {
        padding: 30px 20px;
        max-width: 335px;
    }

    .checkEmail .title {
        font-size: 24px;
    }

    .checkEmail .content {
        font-size: 16px;
        line-height: 1.25;
    }

    .checkEmail .copyright {
        font-size: 14px;
        line-height: 1.29;
    }

    .checkEmail .shrmlogo {
        top: 20px;
        left: 0;
        right: unset;
        width: 87px;
        height: 50px;
    }
}

@media only screen and (max-width: 360px) {
    .checkEmail .whiteBox {
        padding: 30px 20px;
        max-width: 280px;

    }
}


/* Login page */
.resetPassword .whiteBox {
    padding: 50px 50px !important;

}

.resetPassword .validation_errors {
    color: #cd1414;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    position: absolute}

.resetPassword .form-control {
    margin-bottom: 33px;
    position:relative;
}

.resetPassword .custom-form-control {
    width: 100%;
    height: 100%;
    padding: 15px 18px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    border: 1px solid #8A8A8A;
    margin-top: 10px;
}

.resetPassword .formInputone {
    width: 363px;
}

.resetPassword .helpImage {
    vertical-align: middle;
    cursor: pointer;
}

.MuiPopover-paper:has(.popover) {
    border-radius: 10px;
    box-shadow: none;
}

.popover {
    max-width: 220px;
    padding: 15px 25px;
    border: solid 1px #d6d6d6;
    font-size: 14px;
    line-height: 22px;
    border-radius: 10px;
    box-shadow: none;
}

.resetPassword .resetPsform {
    margin-top: 50px;
}

.resetPassword .field-label {
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    margin-bottom: 10px;
}

.resetPassword .btn_text {
    font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
    font-size: 22px;
    font-weight: 600;
    max-width: 185px;
    width: 46%;
    cursor: pointer;
}

.resetPassword .cancelButton {
    height: 50px;
    border-radius: 6px;
    background-color: #fff;
    color: #1B3C69;
    box-shadow: none;
    border: 1px solid #1B3C69 !important;
}

.resetPassword .saveButton {
    height: 50px;
    border-radius: 6px;
    background-color: #1B3C69;
    color: #fff;
    box-shadow: none;
    border: 1px solid #1B3C69 !important;
}

::placeholder {
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-size: 18px;
    font-weight: 300;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-size: 18px;
    font-weight: 300;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-size: 18px;
    font-weight: 300;
}

@media only screen and (max-width: 960px) {
    .resetPassword .whiteBox {
        padding: 30px 20px !important;
        max-width: 335px;
    }

    .resetPassword .formInputone {
        max-width: 260px;
        width: 90%;
    }

    .resetPassword .resetPsform {
        margin-top: 30px;
    }
    .resetPassword .field-label {
        font-size: 16px;
    }
}