body{
  font-family: ProximaNova-Regular, Serif, Sans-serif !important ;
}
.displayRow {
  display: flex;
  flex-direction: row;
}
.content-center {
  justify-content: space-between;
}
.displayCol {
  display: flex;
  flex-direction: column !important;
}
.align-center {
  align-items: center;
}
.p1 {
  padding: 1rem;
}
.p2 {
  padding: 2rem;
}
.pb1 {
  padding-bottom: 1rem;
}
.head {
  padding: 0 1rem 0 1rem;
}
.button {
  width: 160px;
  height: 54px;
  margin: 0 0 0 16px;
  padding: 15px 16px 14px;
  background-color: #f26a42 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 22px !important;
}
.testContainer {
  padding: 1rem 1rem 1.5rem 1rem;
}
.mcqTestSection {
  /* border: 1px solid black; */
  position: relative;
}
.arrowIconContainer {
  position: absolute;
  top: 45% !important;
  right: 0 !important;
}
.arrowIconContainerAr {
  position: absolute;
  top: 45% !important;
  left: 0 !important;
}
.arrowIcon {
  font-size: 18px !important;
}
.queContainer {
  position: relative;
  margin: 0 !important;
}
.flagIcon {
  position: absolute;
  top: 0;
  right: 0 !important;
}
.flagIconAr {
  position: absolute;
  top: 0;
  left: 0 !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.queController {
  padding-left: 1.5rem;
}
.questions {
  padding: 2rem;
}
.queIcon {
  width: 28px;
  height: 28px;
  margin: 30px 12px 25px 0;
  object-fit: contain;
}
.que {
  font-size: 18px;
  font-weight: bold !important;
  font-family: ProximaNova-Bold, Serif, Sans-serif !important ;
}
.btns {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.prevBtn {
  margin-right: 2rem;
  border-color: #1b3c69 !important;
  color: #1b3c69 !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important 
}
.nextBtn {
  background-color: #1b3c69 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important 
}
.finish {
  text-transform: capitalize !important;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important 
}
.numbers {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiGrid-spacing-xs-1 {
  margin: 0px !important;
}
.queController .number {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.answered {
  background-color: #dff8d2;
}
.unAnswered {
  background-color: #eff6ff;
}
.flag {
  background-color: #fbb8b8;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.image {
  display: flex;
  align-items: center;
}

.radio {
  color: #1b3c69 !important;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .paper {
  background-color: white;
  padding: 2rem;
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 10px;
  outline: none !important;
}
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important 
}
.warning .title {
  width: 123px;
  height: 30px;
  margin: 13px 198px 10px 164px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #f94b4b;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important 
}
.warning .btnCancel {
  width: 140px;
  height: 50px;
  margin: 30px 30px 0 70px;
  padding: 13px 16px 12px;
  border-radius: 8px;
  border: solid 2px #f94b4b !important;
  background-color: #fff;
  color: #f94b4b !important;
}
.warning .btnSubmit {
  width: 140px;
  height: 50px;
  margin: 30px 105px 0 30px;
  padding: 13px 16px 12px;
  background-color: #f94b4b !important;
}
.imgFile {
  height: 500px;
  width: 500px;
  margin: 1rem;
}
.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error {
  color: red;
  margin: 0 0 1rem 1.5rem;
}
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.imgSize{
  height: 2.5rem;
  width: 2.5rem;
}
.disableBtn{
  text-transform: capitalize !important;
}
.queImg{
  margin: 1rem 0 0 0;
  max-width: 500px;
  max-height: 500px;
 width:300px;
 height:200px;
}