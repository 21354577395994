body {
  background-color: "darkslategrey";
  /* font-family: 'Proxima Nova Rg'; */
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}
a{
  text-decoration: none;
}
.view_basic_info {
  /* width: 900px; */
  /* height: 1072px; */
  margin: 95px 105;
  padding: 50px 50px 50px 49px;
  border-radius: 20px;
  background-color: #fff;
}
.page_title {
  font-weight: 600;
  height: 50px;
  align-items: center;
  font-size: 32px;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
  padding-left: 12px;
}
.image_logo {
  /* width: 166px; */
  /* height: 95px; */
  padding-top: 30px;
  padding-right: 30px;
}
.select_feild_container {
  width: 244px;
  width: 100%;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}
.textlabel_text {
  width: 95px;
  height: 24px;
  /* margin: 0 10px 3px 1px; */
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}
.basic_info_submit_btn {
  width: 127px;
  height: 60px;
  background: #f26a42 !important;
  color: white !important;
  text-transform: none !important;
  margin: 15px 0px 12px 7px !important;
  padding: 18px 16px;
}
.input-feild-containers {
  min-width: 100%;
  width: 100%;
  /* width: 383px; */
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.input-feild-containers::-webkit-input-placeholder {
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}

.basic_info_submit_btn::-webkit-input-placeholder {
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}
.option-placeholder {
  color: #999;
}
.select_input_feild {
  /* width: 383px; */
  width: 100%;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.select_input_feild:hover {
  border-bottom: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.select_input_feild::before {
  border-bottom: 0;
}
.select-field {
  width: 100% !important;
}
.select-field-rectange-2 {
  width: 100% !important;
}
.input_grid_3 {
  width: 244px;
  height: 52px;
  padding: 15px 11px 13px 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.MuiFormControl-root .MuiInputLabel-root {
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}
.login-form {
  justify-content: center;
  min-height: 90vh;
}
.button-block {
  width: 100%;
}
.login-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}
.forget-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
  width: 51vh;
}

.select_input_component {
  min-width: "100%";
}
.logo_image {
  max-width: 166px;
  height: 95px;
}
.MuiOutlinedInput-root {
  height: 52px !important;
}
.basic-info-textariafeild {
  width: 100%;
  height: 115px;
  /* margin: 15px 0 0 149px;  */
  padding: 14px 5px 77px 17px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px !important;
  font-weight: 400;
  font-family: inherit;
}
.submit_btn_container {
  display: flex;
  justify-content: right;
}
.MuiFormHelperText-contained {
  margin: 0;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .view_basic_info {
    /* width: 100%; */
    /* height: auto; */
    margin: 10px 0px 10px 0px;
    margin-top: 75px;

    padding: 2px;
  }

  .basic_info_container {
    width: 100%;
    padding: 15px;
  }

  .input-feild-containers {
    display: grid;
    width: 100%;
  }
  .select-field {
    width: 100%;
  }
  .select-field-rectange-2 {
    width: 100%;
  }
  .select-input-container {
    min-width: 80%;
  }
  .select_input_component {
    min-width: auto;
  }
  .page_title {
    font-size: 25px;
  }
  
  .logo_image {
    width: 100px;
    height: 60px;
  }
  .basic_info_submit_btn {
    width: 100%;
    height: 60px;
    background: rgb(242, 106, 66);
    color: white;
    text-transform: none;
    margin: 15px 21px 10px 30px;
    padding: 18px 16px;
    font-size: 21px !important;
  }
}
@media only screen and (min-width: 992px) {
  .view_basic_info {
    /* width: 800px; */
    /* height: 1072px; */
    margin: 60px 70px;
    /* padding: 50px 50px 50px 49px; */
    border-radius: 20px;
    background-color: #fff;
  }
  .logo_image {
    width: 106px;
    height: 65px;
  }

  .forget_password_container {
    margin: 226px 447px 136px 419px;
}
}
@media only screen and (min-width: 1280px) {
  .view_basic_info {
    /* width: 900px; */
    /* height: 1072px; */
    margin: 60px 70px;
    /* padding: 50px 50px 50px 49px; */
    border-radius: 20px;
    background-color: #fff;
  }
  .logo_image {
    width: 166px;
    height: 95px;
  }
  .image_logo {
    padding-right: 0;
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .image_logo {
    top: 0px;
    left: 17px;
    padding-top: 30px;
    padding-right: 30px;
    margin: 0;
  }
  .view_basic_info {
    margin: 60px 15px 10px 15px;
  }
  .basic-info-textariafeild {
    width: 100%;
  }
  .basic_info_submit_btn {
    text-transform: none !important;
    background-color: #f26a42 !important;
    width: 100%;
    margin: 12px 0px 10px 0px !important;
    color: #fff !important;
  }
  .forget_password_container {
    margin: 169px 20px 200px 20px !important;
  }
  .forget_pass_block{
    padding: 30px 20px 25px 21px !important;
  }
  .forget_btns{
    width: 100% !important;
    margin: 10px 0px 0 0px !important;
  } 
  .email-input_container{
  padding: 22px 0 15px 0px;
  }
  .forget_password_title{
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .view_basic_info {
    padding: 18px !important;
  }
  .logo_image {
    width: 105px !important;
    height: 60px !important;
  }
  .forget_password_container{
    margin: 189px 208px 136px 208px !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {

  .forget_password_container {
    margin: 150px 208px 136px 208px !important;
}
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 15px !important;
}
.button_grid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 30px !important; */
  margin: 12px !important;
}
/* forget password page css */
.basic_form{
  margin: 0 !important;
}
.forget_password_container {
  margin: 226px 555px 136px 419px; 

  /* background-color: #fff; */
}
.forget_pass_block{
  padding: 64px 41px 50px 42px;
  border-radius: 20px;
  background-color: #fff;
}
.forget_btns {
  /* width: 100%; */
  margin: 6px 9px 0 20px !important ; 
  padding: 0!important;
  padding: 10px 30px;
  border: 1px solid #1b3c69;
  border-radius: 10px !important;
  height: 50px !important;
  text-transform: none !important;
  font-family: ProximaNova, Serif, Sans-serif !important;
  font-size: 18px;
}
.forget_btnsleft .MuiButton-label{
  border: 1px solid  #1b3c69;
  color: #1b3c69;
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 18px;
}
.forget_btns .MuiButton-label{
  font-size: 18px;
}
.text-label-forget-password {
  margin: 69px 0 30px;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center;
  color: #000;
}
.forget_password_title {
  margin: 0 4px 18px 0px !important;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
  font-size: 25px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1b3c69;
}
.email-input_container{
  padding: 15px 11px 13px 18px !important;
}