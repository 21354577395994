.table-Pagination-Data {
    display: flex;
    justify-content: flex-end;
    margin: 3rem;
    color: #f94b4b !important;
}
 
 .table-Pagination-Data .MuiPagination-ul .Mui-selected{
    background-color: #f94b4b !important;
    color: #fff !important;
    font-weight: bold ;
  }
  
  .table-Pagination-Data .MuiPaginationItem-page {
    color: #bebebe;
    border-radius: 6px;
    border: solid 1px #bebebe !important;
    background-color: #fafafa !important;
  }
  
  .table-Pagination-Data nav ul li:last-child > button {
    border: 1px solid #f94b4b !important ;
    color: #f94b4b;
    border-radius: 6px;
  }
  
 .table-Pagination-Data nav ul li:first-child > button {
    border: 1px solid #f94b4b !important ;
    color: #f94b4b;
    border-radius: 6px;
  }
  
  .table-Pagination-Data nav ul li:nth-child(2) > button {
    border: 1px solid #f94b4b !important ;
    color: #f94b4b;
    border-radius: 6px;
  }
  
 .table-Pagination-Data nav ul li:nth-last-child(2) > button {
    border: 1px solid #f94b4b !important ;
    color: #f94b4b;
    border-radius: 6px;
  }
  /* Question Container */
  .questionContainer {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .questionContainer  .flag{
    position: absolute;
    top: 0 !important;
    background-color: #ffd9d8 !important;
    color: #f94b4b !important;
    left: 0;
  }

.disabled {
  background-color: hsl(0,0%,95%);
}

.competency .react-select__option label{
  margin-left: 15px;
}

.competency .comp_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.competency .unchecked {
  width: 26px;
  height: 26px;
  border: 1px solid #484848;
  border-radius: 6px;
}

.competency .checked {
  width: 26px;
  height: 26px;
  background-color: #f94b4b;
  border: 1px solid #f94b4b;
  border-radius: 6px;
}

.competency .checked svg {
  color: #fff;
}

.competency .react-select__multi-value__remove:hover {
  background-color: #f94b4b;
  color: #fff;
}

.competency .react-select__value-container {
  margin-left: 9px;
}

.competency .react-select__menu-list {
  max-height: 170px;
}

.competency .react-select__menu {
  color: black;
}

.competency .react-select__option {
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  color: black;
  margin-top: 0px;
  padding: 0px 12px;
  padding-left: 0px;
}

.competency .react-select__option--is-selected {
  font-family: 'ProximaNova-Semibold' , sans-serif;
}

.competency .react-select__option:hover {
  background-color: #fff;
  font-family: 'ProximaNova-Semibold' , sans-serif;
  color: black;
}

.competency .react-select__option:hover .MuiButtonBase-root:hover {
  background-color: transparent;
}

.competency .react-select__option:active { 
  background-color: #fff;
  color: black;
  font-family: 'ProximaNova-Semibold' , sans-serif;
}

.competency .react-select__multi-value {
  border-radius: 6px;
  color: #fff;
  background-color: #f94b4b;
  padding: 4px;
}

.competency .react-select__multi-value__label {
  color: #fff;
}

.setScoreRating br{
  margin-bottom: 20px;
}

.customUploadArea {
  width: 100%;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 250px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customUploadArea .centerText svg{
  width: 51px;
  height: 51px;
}

.rating-dropdown .react-select__menu-list {
  max-height: 210px;
}