.dashboard-title {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}

.dashboard-title .title {
  font-size: 30px;
  font-family: ProximaNova-Bold, Serif, Sans-serif !important;
  padding: 15px 0px 30px 0px;
}

.text {
  font-size: 14px !important;
  margin-bottom: 6px !important;
  font-family: ProximaNova-Light, Serif, Sans-serif !important;
}

.card {
  box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.12) !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  border: solid 1px #e7e7e7;
}

.card .MuiGrid-container {
  min-height: 210px;
}

.item-1 {
  text-align: center;
  padding: 1rem;
}

.item-2 span {
  color: black;
}

.card-heading {
  font-size: 22px !important;
  margin: 15px 0px 10px 0px !important;
  color: black !important;
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
}

.card-description {
  line-height: 20px;
  font-size: 16px !important;
  margin: 0px 6px !important;
  font-family: ProximaNova-Light, Serif, Sans-serif !important;
}

.item-2 {
  border: 0.4px solid #eee;
  padding: 20px 15px 20px 15px !important;
}

.evaluation {
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.date {
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  font-size: 16px !important;
  text-transform: capitalize !important;

}

.duration {
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  font-size: 16px !important;
}

.progress {
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  font-size: 14px !important;
}

.button-section button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Assessor Dashboard */
.linkModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkModal .paper {
  background-color: white;
  padding: 3rem;
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 10px;
  outline: none !important;
}

/* colors */
.blue {
  background-color: #1b3c69 !important;
  color: white !important;
}

.dashboard .MuiLinearProgress-barColorPrimary {
  background-color: #288a0a !important;
}

.greenAssordashbord {
  background-color: #288a0a !important;
  color: white !important;
}

.golden {
  background-color: #cda91f !important;
  color: white !important;
}
.orange {
  background-color: #f26a42 !important;
  color: white !important;
}

.toolCard {
  /* background-color: rg!b(239, 237, 237) !important; */
  border-top: 1px solid rgb(222, 221, 221) !important;
  border-left: 1px solid rgb(222, 221, 221) !important;
  border-right: 1px solid rgb(222, 221, 221) !important;
  border-bottom: none !important;
  border-radius: 10px !important;
  box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.12) !important;
  border: solid 1px #e7e7e7 !important;
  background-color: #fff !important;
}

.toolCard .tool .image {
  height: 70px;
  width: 70px !important;
  margin: 1rem;
}

.toolCard .tool {
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.toolCard .heading {
  font-size: 22px !important;
  margin: 15px 0px 10px 0px !important;
  color: black !important;
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
}

.toolCard .description {
  text-align: center !important;
  line-height: 20px;
  font-size: 16px !important;
  margin: 0px 6px !important;
  font-family: ProximaNova-Light, Serif, Sans-serif !important;
}

.toolCard .content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(229, 226, 226);
  border-collapse: inherit !important;
}

.toolCard .content .contentTitle {
  font-size: 1rem !important;
  margin-left: none !important;
  margin-bottom: 0.8rem;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;

}

.toolCard .content .value {
  font-weight: bold;
  text-transform: capitalize;
  font-family: ProximaNova-Bold, Serif, Sans-serif !important;

}

.toolCard .custombtn {
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0.7rem !important;
  border-bottom-right-radius: 0.7rem !important;
  background: transparent;
  padding: 1rem !important;
  text-transform: capitalize;
  font-size: 20px;
}

.toolCard .progressbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.toolCard .Mui-disabled {
  background-color: #bababa !important;
}
.goToDashboard{
  text-transform :none !important;
  color: #1b3c69 !important;
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
  font-size: 18px;
  padding: 5px !important;
}
.goToDashboard:hover{
  background-color: transparent !important;
}
.goToDashboard:active{
  background-color: transparent !important;
}
.btn{
  padding: 1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
}
.btnYes{
  background-color: #f26a42 !important;
  color: white !important;
  font-family: ProximaNova-Bold, Serif, Sans-serif !important;
  text-transform: capitalize !important;
}
.btnYes:hover{
  border:1px solid #f26a42 !important;
  color: #f26a42 !important;
  background-color: transparent !important;
}
.btnNO{
  border:1px solid #f26a42 !important;
  color: #f26a42 !important;
  background-color: transparent !important;
  font-family: ProximaNova-Bold, Serif, Sans-serif !important;
  text-transform: capitalize !important;
}
.btnNo:hover{
  background-color: #f26a42 !important;
  color: white !important;
}