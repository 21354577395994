.test-instruction {
  font-family: ProximaNova-Light , Serif, Sans-serif !important;
  font-size: 18px;
  line-height: 1.44;
}

.test-instruction .top {
  margin-top: 0px;
}

.test-instruction .top .title {
  font-size: 30px;
  padding: 15px 0px 30px 0px;
  font-family: ProximaNova-bold,  Serif, Sans-serif !important;
}

.test-instruction .info {
  margin: 50px 0px 20px 0px;
  font-family: ProximaNova-Light , Serif, Sans-serif !important;
  font-size: 18px;
}

.test-instruction ul {
  margin-left: 16px;
}

.test-instruction ul li {
  margin-bottom: 10px;
}

.test-instruction ul li::marker {
  color: #8aaddc;
  font-size: 22px;
}

.test-instruction ul li span {
  font-size: 18px;
  font-family: ProximaNova-Bold , Serif, Sans-serif !important;
  color: #1b3c69;
}

.test-instruction .center-title {
  margin-top: 50px;
  text-align: center;
  color: #1b3c69;
  font-size: 22px;
  font-family: ProximaNova-Semibold , Serif, Sans-serif !important;
}

.test-instruction .title-info {
  margin-bottom: 22px;
  font-size: 22px;
  font-family: ProximaNova-Semibold , Serif, Sans-serif !important;
}

.test-instruction hr {
  height: 1px;
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #e1e1e1;
}

.test-instruction .content,
.test-instruction .MuiFormControlLabel-label {
  font-family: ProximaNova-Light , Serif, Sans-serif !important;
  font-size: 18px !important;
}
