.page .login-form {
  justify-content: center;
  min-height: 30vh;
  border-radius: 20px !important;
  width: 500px !important
}

.page .login-outer {
  margin-top: 1rem !important;
}

.page .button-block {
  width: 100%;
  background-color: #f26a42 !important;
  padding: 10px !important;
  margin-bottom: 1rem !important;
  font-family: ProximaNova-Semibold , Serif, Sans-serif !important;
  font-size: 22px !important;
  text-transform: capitalize !important;
}

.page .login-background {
  justify-content: center;
  min-height: 45vh;
  padding: 50px;
  border-radius: 20px !important;
}

.page .form-heading {
  margin-bottom: 1.5rem !important;
  font-family: ProximaNova-Bold , Serif, Sans-serif !important;
  font-size: 30px !important;
}

.page .fields {
  margin-bottom: 1.2rem !important;
}

.page .forgot-password {
  text-align: center !important;
  text-decoration: underline !important;
  font-family: ProximaNova-Light , Serif, Sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #1b3c69
}

.page {
  background-color: #1b3c69 !important;
  padding: 50px 20px;
}

.page .footer-text {
  text-align: center !important;
  color: #8295af !important;
  margin-top: 20px !important;
  font-size: 14px !important;
}

.page .labels {
  font-family: ProximaNova-Light , Serif, Sans-serif !important;
  font-size: 18px !important;
  font-weight: 300 !important;
}

.page .text {
  margin-top: 8px !important;
}
.page .MuiSvgIcon-root{
  color:white !important
  
}

