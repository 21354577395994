.label-parent .AddParticipantBtn {

    background-color: #f94b4b;
    color: white;
    padding: 10px 30px !important;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 18px;
    text-transform: capitalize;
    font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
}

.add-client .ModalBtn {
    background-color: #f94b4b !important;
    color: white !important;
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    text-transform: capitalize !important;
    margin: 0px 30px 0px 0px;

}

.label-parent .labels {
    font-family: ProximaNova-Regular , Serif, Sans-serif !important;
    font-size: 18px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black !important
}

.dialog-title .MuiTypography-h6 {
    color: #1b3c69;
    padding-top: 40px;
    font-family: ProximaNova-Bold, Serif, Sans-serif !important;
    font-size: 30px !important;

}

.textfield-parent fieldset {
    border: 0;
}

.label-parent .client-textfields {
    border-radius: 15px;
    box-shadow: 0;
    border: solid 1px #787878;
    background-color: #fff;
    margin-top: 15px !important;
}

.createNewProject .client-textfields {
    border-radius: 15px;
    box-shadow: 0;
    border: solid 1px #d6d6d6;
    background-color: #fff;
    margin-top: 0px !important;
}

.createNewProject .uploadIcon {
    height: 40px;
}

.createNewProject .viewUploadedFiles a{
    color: #fff;
}

.createNewProject .viewUploadedFiles {
    margin: 15px 10px 0 0;
    padding: 10px;
    border-radius: 10px;
    background: #f94b4b;
}


.createNewProject .react-select__single-value {
 color: #333 !important;
}

.createNewProject .radio .MuiTypography-root {
    font-size:12px;
}

.createNewProject .radio .MuiIconButton-root {
    padding: 0 9px;
}

.createNewProject .react-select__value-container .css-1rhbuit-multiValue{
    padding: 3px 10px 5px;
    border-radius: 6px;
    background-color: #f94b4b;
    color: #fff;
}

.createNewProject .react-select__value-container .css-1rhbuit-multiValue div{
    color: #fff;
}

.createNewProject .MuiDropzoneArea-text {
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    font-size: 18px;
    margin-top: 65px;
}

.createNewProject .Mui-disabled input{
    color: #333;
    background-color: hsl(0,0%,95%);
}

.createNewProject .Mui-disabled fieldset{
    border: 1px solid #d6d6d6 !important;
    border-radius: 10px !important;
}
.label-parent .client-select {
    height: 3.4rem !important;
}

.label-parent .validation_errors {
    color: #cd1414;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    position: absolute;
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-weight: 300;
    opacity: 1;
    /* Firefox */
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-weight: 300;
    opacity: 1;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8A8A8A;
    font-family: ProximaNova-Light, Serif, Sans-serif !important;
    font-weight: 300;
    opacity: 1;
}

.customChart {
    height: 250px !important;
}

.analyticsPage .card {
    /* height: 284px; */
    margin: 20px 20px 0px 0;
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #bebebe;
    background-color: #fff;
}

.analyticsPage .card .MuiCardContent-root {
    padding: 0;
}

.analyticsPage .recharts-cartesian-axis-tick {    
    font-size: 12px;
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
}
.analyticsPage .recharts-legend-wrapper {
    height: 60px !important;
    overflow: auto;
}
.analyticsPage .CardTitle {
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    font-size: 20px;
    color: #000;
}
.analyticsPage .sort_analytics {
    /* width: 200px; */
    display:flex;
    margin-right: 20px;
}

.analyticsPage .sort_analytics .react-select__control{
    width: 200px;
    margin-right: 20px;
}

.analyticsPage .newLegend .legend-item{
    display: flex;
    justify-content: space-between;
    width:100%;
}

.analyticsPage .newLegend .legend-wrapper{
    display: flex;

}

.analyticsPage .newLegend {
    display:flex;
    flex-direction: column;
}

.analyticsPage .newLegend  svg{
   margin-right: 5px;
}

.analyticsPage .dropdownBtn {
    width: 200px;
    border: 1px solid #d6d6d6;
    margin-right: 20px;
    padding: 10px;
    color: #1b3c69;
}
.analyticsPage .dropdownBtn span{
    justify-content: space-between;
    text-transform: capitalize;
}

.analyticsPage .MuiGrid-spacing-xs-2 {
    width: 100%;
}

.innerAccordion {
    width: 100%;
    padding: 0 !important;
}

.innerAccordion div {
    width: 100%;
}

.activeAccordion {
    background: #eff6ff !important;
}

.analyticsPage .noDataFound {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolsbuttons .UnSelectBtn {
    border: 1px solid #333;
    border-radius: 5px;
    margin: 10px 10px 0px 0;
    cursor: pointer;
}

.toolsbuttons .SelectBtn {
    border: 1px solid #f94b4c;
    border-radius: 5px;
    margin: 10px 10px 0px 0;
    cursor: pointer;
    background-color: #f94b4c ;
    color: white;
}

.toolsbuttons {
    display: flex;
    flex-direction: row;
}

.resetContainer {
    padding: 15px 0;
}

.dragElement {
    position: relative;
}

.dragElement .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.label-file-upload.drag-active > div {
    background-color: rgb(239, 246, 255);
}


.label-file-upload.drag-active {
    background-color: rgb(239, 246, 255);
}