.thankyou .box-logo{
    margin-bottom: 20px !important;
}
.thankyou .thanks-typo{
    margin-bottom: 20px !important;
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    font-size: 20px !important;
}
.thankyou{
    margin: 30px;
}
.thankyou .thanks-section{
    margin-top: 100px !important;
    margin-bottom: 25px !important;
}
.thanks-footer{
    background-color: #161362 !important;
    padding: 30px 0 !important;
    margin: 30px;
}
.thanks-footer .footer-text{
    text-align: center !important;
    color: white !important;
}
.thanks-bold-parent .thanks-bold {
    font-family: ProximaNova-Bold, Serif, Sans-serif !important;
    font-size: 24px !important;
    line-height: 26px !important;
    color:green ;
    margin-bottom: 20px !important;
}
.thanks-bold{
    color: green;
}
.thanks-section .thanksss{
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    font-size: 18px !important;
    line-height: 24px !important;
}
