.manage-assessor {
  width: 100%;
}

.manage-assessor .sidebar {
  width: 330px;
  min-height: 100vh;
  margin: 0 30px 0 0;
  padding: 20px;
  background-color: #1b3c69;
}

.manage-assessor .main_page {
  margin-top: 30px;
  margin-left: 0 !important;
}

@media only screen and (max-width: 960px) {
  .manage-assessor .main_page {
    margin-left: 0;
    padding: 20px;
  }
}

.manage-assessor .client_container h4 {
  font-family: ProximaNova-Bold, Serif, Sans-serif !important;
  font-size: 44px;
  color: #1b3c69;
}

.manage-assessor .client_container p {
  font-family: ProximaNova-Regular, Serif, Sans-serif !important;
  font-size: 16px;
  color: #1b3c69;
}

.manage-assessor .client_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-assessor-btns {
  width: 204px;
  height: 54px;
  margin: 0px 30px 5px 535px;
  padding: 18px 16px 14px;
  border-radius: 5px;
  background-color: #f94b4b;
}

.manage-assessor-btns .btn-text {
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.09;
  text-align: center;
  color: #fff;
  padding: 11px;
}

.manage-assessor-table {
  border-radius: 10px;
  border: solid 1px #bebebe;
}

.manage-client-table .MuiTableCell-alignCenter {
  padding-left: 30px !important;
}

.manage-client-table .MuiTableCell-root {
  padding: 20px 10px !important;
}

.editModal .validation_errors {
  color: #cd1414;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: justify;
  position: absolute;
}

.editModal .saveBtn {
  width: 109px;
  height: 50px;
  color: white !important;
  font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
  margin: 30px 83px 15px 0 !important;
  padding: 13px 16px 12px;
  background-color: #f94b4b !important;
  border-radius: 8px;
  text-transform: capitalize !important;
  font-size: 22px !important;
}

.editModal .cancelBtn {
  width: 108px;
  height: 50px;
  margin: 30px 30px 15px 0px;
  padding: 13px 16px 12px;
  border-radius: 8px;
  color: #f94b4b !important;
  border: solid 1px #f94b4b !important;
  background-color: #fff !important;
  text-transform: capitalize !important;
  font-size: 22px !important;
  line-height: 1;
  min-height: 54px;
}

.editModal .view_rect {
  width: auto;
  height: 50px;
  margin: 10px 10px 10px 0;
  padding: 12px 19px 12px 20px;
  border-radius: 10px;
  color: #fff !important;
  background-color: #f94b4b !important;
}

.manage-assessor .table-Pagination-Data td {
  border-bottom: none;
}

.editModal .view_rect_2 {
  width: auto;
  height: 50px;
  margin: 10px 10px 10px 8px !important;
  padding: 12px 20px;
  border-radius: 10px;
  border: solid 1px #484848 !important;
  background-color: #fff !important;
}

.manage-assessor .table-Pagination-Data {
  display: flex;
  justify-content: flex-end;
  margin: 3rem;
  color: #f94b4b !important;
}


.manage-assessor .table-Pagination-Data .MuiPagination-ul .Mui-selected {
  background-color: #f94b4b !important;
  color: #fff !important;
  font-weight: bold;
}

.manage-assessor .table-Pagination-Data .MuiPaginationItem-page {
  color: #bebebe;
  border-radius: 6px;
  border: solid 1px #bebebe !important;
  background-color: #fafafa !important;
}

.manage-assessor .table-Pagination-Data nav ul li:last-child>button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.manage-assessor .table-Pagination-Data nav ul li:first-child>button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.manage-assessor .table-Pagination-Data nav ul li:nth-child(2)>button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.manage-assessor .table-Pagination-Data nav ul li:nth-last-child(2)>button {
  border: 1px solid #f94b4b !important;
  color: #f94b4b;
  border-radius: 6px;
}

.manage-assessor .searchInput::-webkit-input-placeholder {
  font-family: ProximaNova-Regular, Arial, Helvetica, sans-serif;
}

.manage-assessor .searchInput:-ms-input-placeholder {
  font-family: ProximaNova-Regular, Arial, Helvetica, sans-serif;
}

.manage-assessor .searchInput:-moz-placeholder {
  font-family: ProximaNova-Regular, Arial, Helvetica, sans-serif;
}

.manage-assessor .searchInput::-moz-placeholder {
  font-family: ProximaNova-Regular, Arial, Helvetica, sans-serif;
}

.deleteParticipantModal .parent-btn {
  display: flex;
  justify-content: center;
}

.deleteParticipantModal .title {
  margin: 30px 0;
}

.deleteParticipantModal .centerText {
  text-align: center;
}

.fontSTyle {
  font-family: ProximaNova-Regular, serif,sans-serif !important;
  font-size: 18px !important;
  color: #000 !important;
}