
.manage-client{
    width: 100%;
  }
  
  .manage-client .sidebar{
    width: 330px;
    min-height: 100vh;
    margin: 0 30px 0 0;
    padding: 20px;
    background-color: #1b3c69;
    position: relative;
  }
  
  .manage-client .main_page{
    margin-top: 30px;
  }

  @media only screen and (max-width: 960px) {
    .manage-client .main_page {
      margin-left: 0;
      padding: 20px;
    }
  }
  .manage-client .client_container h4{
    font-family: ProximaNova-Bold, Serif, Sans-serif !important;
    font-size: 44px;
    color: #1b3c69;
  }
  
  .manage-client .client_container p{
    font-family: ProximaNova-Regular, Serif, Sans-serif !important;
    font-size: 16px;
    color: #1b3c69;
  }

  .manage-client .client_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .manage-client-btns{
    width: 204px;
    height: 54px;
    margin: 0px 30px 5px 535px;
    padding: 18px 16px 14px;
    border-radius: 5px;
    background-color: #f94b4b;
  }

  .manage-client-btns .btn-text{
    font-family: ProximaNova-Semibold, Serif, Sans-serif !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.09;
    text-align: center;
    color: #fff;
   padding: 11px;
  }

  .manage-client-table{
    border-radius: 10px;
    border: solid 1px #bebebe;
  }

  .manage-client .MuiTableCell-alignCenter{
    text-align: start !important; 
    padding-left: 30px !important;
  }

  .manage-client .ActionClass{
    text-align: center !important;
  }

  .manage-client .MuiTableCell-root{
   padding: 20px 15px !important;
  }

  .manage-client .table-Pagination-Data {
    display: flex;
    justify-content: flex-end;
    margin: 3rem;
    color: #f94b4b !important;
}


.manage-client .table-Pagination-Data .MuiPagination-ul .Mui-selected {
    background-color: #f94b4b !important;
    color: #fff !important;
    font-weight: bold;
}

.manage-client .table-Pagination-Data .MuiPaginationItem-page {
    color: #bebebe;
    border-radius: 6px;
    border: solid 1px #bebebe !important;
    background-color: #fafafa !important;
}

.manage-client .table-Pagination-Data nav ul li:last-child>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.manage-client .table-Pagination-Data nav ul li:first-child>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.manage-client .table-Pagination-Data nav ul li:nth-child(2)>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.manage-client .table-Pagination-Data nav ul li:nth-last-child(2)>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

 






























  /* .filter-bg{
    height: 135px;
    display: flex;
    margin: 40px 30px 50px 0px;
    align-items: baseline;
    background-color:#eff6ff;;
  }


  .filter-container{
    display: flex;
    margin-top: 2% ;
    padding-left: 30px;
  }

  .label-text{
    font-family: ProximaNova-Regular;
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b3c69;
  } */

