@font-face {
    font-family: "Proxima Nova Black It";
    src: local("Proxima Nova Black It"),
        url("../assets/fonts/Proxima Nova Black It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Black It";
    src: local("Proxima Nova Cond Black It"),
        url("../assets/fonts/Proxima Nova Cond Black It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Black";
    src: local("Proxima Nova Cond Black"),
        url("../assets/fonts/Proxima Nova Cond Black.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Bold It";
    src: local("Proxima Nova Cond Bold It"),
        url("../assets/fonts/Proxima Nova Cond Bold It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Bold";
    src: local("Proxima Nova Cond Bold"),
        url("../assets/fonts/Proxima Nova Cond Bold.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Thin It";
    src: local("Proxima Nova Cond Thin It"),
        url("../assets/fonts/Proxima Nova Cond Thin It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Thin";
    src: local("Proxima Nova Cond Thin"),
        url("../assets/fonts/Proxima Nova Cond Thin.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Xbold It";
    src: local("Proxima Nova Cond Xbold It"),
        url("../assets/fonts/Proxima Nova Cond Xbold It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Cond Xbold";
    src: local("Proxima Nova Cond Xbold"),
        url("../assets/fonts/Proxima Nova Cond Xbold.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Black It";
    src: local("Proxima Nova ExCn Black It"),
        url("../assets/fonts/Proxima Nova ExCn Black It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Black";
    src: local("Proxima Nova ExCn Black"),
        url("../assets/fonts/Proxima Nova ExCn Black.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Bold It";
    src: local("Proxima Nova ExCn Bold It"),
        url("../assets/fonts/Proxima Nova ExCn Bold It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Bold";
    src: local("Proxima Nova ExCn Bold"),
        url("../assets/fonts/Proxima Nova ExCn Bold.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Light It";
    src: local("Proxima Nova ExCn Light It"),
        url("../assets/fonts/Proxima Nova ExCn Light It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Light";
    src: local("Proxima Nova ExCn Light"),
        url("../assets/fonts/Proxima Nova ExCn Light.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Reg It";
    src: local("Proxima Nova ExCn Reg It"),
        url("../assets/fonts/Proxima Nova ExCn Reg It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Reg";
    src: local("Proxima Nova ExCn Reg"),
        url("../assets/fonts/Proxima Nova ExCn Reg.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Sbold It";
    src: local("Proxima Nova ExCn Sbold It"),
        url("../assets/fonts/Proxima Nova ExCn Sbold It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Sbold";
    src: local("Proxima Nova ExCn Sbold"),
        url("../assets/fonts/Proxima Nova ExCn Sbold.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Thin It";
    src: local("Proxima Nova ExCn Thin It"),
        url("../assets/fonts/Proxima Nova ExCn Thin It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Thin";
    src: local("Proxima Nova ExCn Thin"),
        url("../assets/fonts/Proxima Nova ExCn Thin.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Xbold It";
    src: local("Proxima Nova ExCn Xbold It"),
        url("../assets/fonts/Proxima Nova ExCn Xbold It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova ExCn Xbold";
    src: local("Proxima Nova ExCn Xbold"),
        url("../assets/fonts/Proxima Nova ExCn Xbold.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Thin It";
    src: local("Proxima Nova Thin It"),
        url("../assets/fonts/Proxima Nova Thin It.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Thin";
    src: local("Proxima Nova Thin"),
        url("../assets/fonts/Proxima Nova Thin.otf") format("truetype");

}

@font-face {
    font-family: "Proxima Nova Xbold It";
    src: local("Proxima Nova Xbold It"),
        url("../assets/fonts/Proxima Nova Xbold It.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Black";
    src: local("ProximaNova-Black"),
        url("../assets/fonts/ProximaNova-Black.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Bold";
    src: local("ProximaNova-Bold"),
        url("../assets/fonts/ProximaNova-Bold.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-BoldIt";
    src: local("ProximaNova-BoldIt"),
        url("../assets/fonts/ProximaNova-BoldIt.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Extrabold";
    src: local("ProximaNova-Extrabold"),
        url("../assets/fonts/ProximaNova-Extrabold.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Light";
    src: local("ProximaNova-Light"),
        url("../assets/fonts/ProximaNova-Light.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-LightItalic";
    src: local("ProximaNova-LightItalic"),
        url("../assets/fonts/ProximaNova-LightItalic.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Regular";
    src: local("ProximaNova-Regular"),
        url("../assets/fonts/ProximaNova-Regular.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-Regular";
    src: local("ProximaNovaCond-Regular"),
        url("../assets/fonts/ProximaNovaCond-Regular.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-Semibold";
    src: local("ProximaNova-Semibold"),
        url("../assets/fonts/ProximaNova-Semibold.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-SemiboldItalic";
    src: local("ProximaNova-SemiboldItalic"),
        url("../assets/fonts/ProximaNova-SemiboldItalic.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-Light";
    src: local("ProximaNovaCond-Light"),
        url("../assets/fonts/ProximaNovaCond-Light.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-LightIt";
    src: local("ProximaNovaCond-LightIt"),
        url("../assets/fonts/ProximaNovaCond-LightIt.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNova-RegularItalic";
    src: local("ProximaNova-RegularItalic"),
        url("../assets/fonts/ProximaNova-RegularItalic.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-RegularIt";
    src: local("ProximaNovaCond-RegularIt"),
        url("../assets/fonts/ProximaNovaCond-RegularIt.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-Semibold";
    src: local("ProximaNovaCond-Semibold"),
        url("../assets/fonts/ProximaNovaCond-Semibold.otf") format("truetype");

}

@font-face {
    font-family: "ProximaNovaCond-SemiboldIt";
    src: local("ProximaNovaCond-SemiboldIt"),
        url("../assets/fonts/ProximaNovaCond-SemiboldIt.otf") format("truetype");

}


*,
*::before,
*::after {
    box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: "ProximaNova-Regular";
}