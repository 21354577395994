.Project-Document .table-Pagination-Data {
    display: flex;
    justify-content: flex-end;
    margin: 3rem;
    color: #f94b4b !important;
}


.Project-Document .table-Pagination-Data .MuiPagination-ul .Mui-selected {
    background-color: #f94b4b !important;
    color: #fff !important;
    font-weight: bold;
}

.Project-Document .table-Pagination-Data .MuiPaginationItem-page {
    color: #bebebe;
    border-radius: 6px;
    border: solid 1px #bebebe !important;
    background-color: #fafafa !important;
}

.Project-Document .table-Pagination-Data nav ul li:last-child>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.Project-Document .table-Pagination-Data nav ul li:first-child>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.Project-Document .table-Pagination-Data nav ul li:nth-child(2)>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}

.Project-Document .table-Pagination-Data nav ul li:nth-last-child(2)>button {
    border: 1px solid #f94b4b !important;
    color: #f94b4b;
    border-radius: 6px;
}